<template>
  <div class="login">
    <md-card class="contentCard">
      <div class="md-title">{{ getContent(1240) }}</div>
      <div class="md-subhead"></div>

      <form @submit.prevent="checkForm">
        <md-field>
          <label>{{ getContent(1241) }}</label>
          <md-input
            v-model="userdata.name"
            name="name"
            md-clearable
            disabled
          ></md-input>
          <md-button style="margin: 0" @click="getNewName()">{{
            getContent(1373)
          }}</md-button>
        </md-field>
        <md-field>
          <label>{{ getContent(1242) }}</label>
          <md-input
            v-model="userdata.email"
            name="email"
            type="email"
          ></md-input>
        </md-field>
        <md-field>
          <label>{{ getContent(1243) }}</label>
          <md-input
            v-model="userdata.password"
            name="password"
            type="password"
          ></md-input>
        </md-field>
        <md-field :class="userdata.password === password2 ? '' : 'md-invalid'">
          <label>{{ getContent(1244) }}</label>
          <md-input
            v-model="password2"
            name="password"
            type="password"
            md-clearable
          ></md-input>
        </md-field>
        <md-field v-if="embedded && supportType != 1">
          <label>{{ getContent(1245) }}</label>
          <md-textarea
            v-model="userdata.aboutMe"
            name="name"
            md-clearable
            md-autogrow
            maxlength="150"
          ></md-textarea>
        </md-field>
        <md-checkbox v-model="agb" class="md-primary">{{
          getContent(1246)
        }}</md-checkbox>
        <div style="margin-left: 35px">
          <a
            href="../assets/Mind_Support_terms_and_conditions.pdf"
            @click.prevent="
              downloadItem({
                url: `${publicPath}Mind_Support_terms_and_conditions.pdf`,
                label: getContent(1232),
              })
            "
          >
            {{ getContent(1232) }} </a
          ><br />
          <a
            href="../assets/Privacy_Policy_for_Mind_Support_e.pdf"
            @click.prevent="
              downloadItem({
                url: `${publicPath}Privacy_Policy_for_Mind_Support_e.pdf`,
                label: getContent(1233),
              })
            "
          >
            {{ getContent(1233) }}
          </a>
        </div>
        <div v-if="query">
          <md-progress-bar md-mode="query"></md-progress-bar>
        </div>
        <br />
        <p v-if="formErrors" class="warnings">{{ formErrors }}</p>
        <md-card-actions>
          <md-button class="loginButton" @click="navigateLogin()"
            >Login instead</md-button
          >
          <md-button class="md-raised md-primary loginButton" type="submit">{{
            getContent(1247)
          }}</md-button>
        </md-card-actions>
      </form>
    </md-card>
  </div>
</template>
<script>
import { loadData } from "@/utils";
import { axiosDelete, axiosGet, axiosPost } from "@/utils/axiosWrapper";
import { generateName } from "@/utils/generateName";

export default {
  name: "Signup",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
      text: {},

      userdata: {
        name: generateName(),
        email: "",
        password: "",
        aboutMe: "",
      },
      password2: "",
      agb: false,

      formErrors: "",
      query: false,
    };
  },
  props: {
    embedded: {
      type: Boolean,
      default: false,
    },
    answers: {
      type: Array,
      default: () => [],
    },
    supportType: {
      type: Number,
      default: null,
    },
  },
  methods: {
    async signupSubmit() {
      this.query = true;
      let doesUserExist = await axiosGet(`/users/email/${this.userdata.email}`);
      console.log(doesUserExist);

      if (doesUserExist.data) {
        console.warn(
          "User already exists, overwriting old user if he didnt set a password."
        );
        if (doesUserExist.data.pwdHash) {
          this.formErrors =
            "User already exists. Please enter a different E-Mail or try to sign in.";
        } else {
          await axiosDelete(`/users/${doesUserExist.data._id}`);
          this.doSignUp();
        }
      } else {
        this.doSignUp();
      }

      this.query = false;
    },
    doSignUp: function () {
      axiosPost(`/users/`, {
        myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
        text: {},

        user: {
          name: this.userdata.name,
          email: this.userdata.email,
          password: this.userdata.password,
          answers: this.answers,
          aboutMe: this.userdata.aboutMe,
          settings: [
            {
              name: "supportType",
              value: this.supportType,
            },
          ],
        },
      })
        .then((res) => {
          if (this.embedded) this.$emit("signUpComplete", res.data.user);
          else this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          console.warn(err);
          alert(
            "Your Signup was not successfully. If this error continues, feel free to write us an E-Mail at help@mindsupport.eu"
          );
        });
    },
    checkForm: function () {
      this.formErrors = "";
      if (!this.agb)
        this.formErrors =
          "Please accept the privacy statement and the terms of service.";
      if (this.userdata.email === "") {
        this.formErrors = "Email required.";
      } else if (!this.validEmail(this.userdata.email)) {
        this.formErrors = "Valid email required.";
      }
      this.userdata.email = this.userdata.email.toLowerCase();
      if (this.formErrors === "" && this.userdata.password === this.password2) {
        this.signupSubmit();
      }
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    downloadItem({ url, label }) {
      axiosGet(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    getContent(key) {
      return this.text[key];
    },
    navigateLogin: function () {
      if (this.embedded) {
        this.$emit("signin");
        return;
      }
      this.$router.push({ name: "Signin" });
    },
    getNewName() {
      this.userdata.name = generateName();
    },
  },

  async mounted() {
    this.text = {
      ...(await loadData("Signup", this.myLanguage)),
      ...(await loadData("Footer", this.myLanguage)),
    };
  },
};
</script>
<style scoped>
.login {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.contentCard {
  height: 600px;
  padding: 2em;
  width: 50em;
}

.loginButton {
  margin-left: 0;
}

md-progress-bar {
  margin: 10px;
}

.warnings {
  padding-bottom: 30px;
  color: #e53935;
}
</style>
